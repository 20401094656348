/* eslint-disable no-undef */
/* eslint-disable no-useless-catch */
/* eslint-disable no-unused-vars */


import axios from 'axios';


export default {
    namespaced: true,
    state: () => ({
        isAuthenticated: !!localStorage.getItem('token'),
    //   userRole: null, // e.g., 'admin', 'translator', 'recorder'
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    }),
    mutations: {
      SET_AUTHENTICATED(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
      },
    //   SET_USER_ROLE(state, role) {
    //     state.userRole = role;
    //   },
    SET_TOKENS(state, { token, refreshToken }) {
        state.token = token;
        state.refreshToken = refreshToken;
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
      },
      LOGOUT(state) {
        state.isAuthenticated = false;
        // state.userRole = null;
        state.token = null;
        state.refreshToken = null;
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
      }
    },
    actions: {
    async login({ commit }, { email, password }) {
        const auth = `Basic ${btoa(`${email}:${password}`)}`;
        try {
          const response = await axios.post(`${process.env.VUE_APP_SOFA_API}login`, {
            email,
            password,
          }, {
            headers: {
              'Authorization': auth,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Accept',
              'Access-Control-Allow-Credentials': true,
              'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE, HEAD',
            },
          });
  
          const { token, refresh_token } = response.data.responseData;
          commit('SET_TOKENS', { token, refreshToken: refresh_token });
          commit('SET_AUTHENTICATED', true);
        } catch (error) {
          throw error; 
        }
      },
      async handleTwoFa({ commit }, { code, phone,from }) {
        try {
            const response = await axios.post(`${process.env.VUE_APP_SOFA_API}2fa`, {
              code,
              phone,
            }, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
            });
          console.log(from)
          const { token, refresh_token } = response.data.responseData;
          if (from !== 'forget-password') {

          commit('SET_TOKENS', { token, refreshToken: refresh_token });
          commit('SET_AUTHENTICATED', true);
          }
          return { token };
        } catch (error) {
          throw error; 
        }
      },
      async getUpdatedAccessToken({ state, commit }) {
        try {
          // Call the refresh token API
          const response = await axios.post(`${process.env.VUE_APP_SOFA_API}refresh`, {
            refresh_token: state.refreshToken
          });
  
          if (response.status === 200) {
            const newAccessToken = response.data.responseData.token;
  
            // Update the tokens in the Vuex store and localStorage
            commit('SET_TOKENS', { token: newAccessToken, refreshToken: state.refreshToken });
            
            return { token: newAccessToken };
          } else if (response.status === 401) {
            commit('LOGOUT');
            return { responseCode: 401, message: 'Unauthorized' };
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          return { responseCode: 500, message: 'Something went wrong' };
        }
      },
  
      // Integrated callServiceWithUpdatedAccessToken logic
      async callServiceWithUpdatedAccessToken({ dispatch }) {
        try {
          const response = await dispatch('getUpdatedAccessToken');
  
          if (response.token) {
            // New access token was successfully received and stored
            return response.token;
          } else if (response.responseCode === 401) {
            console.error('Unauthorized! Logging out...');
            await dispatch('logout'); // Call the logout action
            return null;
          }
        } catch (error) {
          console.error('Error calling service with updated access token:', error.message);
          return null;
        }
      },
      
      
      logout({ commit }) {
        commit('LOGOUT');
      },
      setRole({ commit }, role) {
        // commit('SET_USER_ROLE', role);
      }
    },
    getters: {
      isAuthenticated: (state) => state.isAuthenticated,
    //   userRole: (state) => state.userRole,
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
    },
  };
  