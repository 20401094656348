<template>
  <div class="audio-recorder">
     <button @click="toggleRecording">
      <i :class="isRecording ? 'fas fa-pause' : 'fas fa-microphone'"></i>
    </button>
    <div v-if="transcription">
      <!-- <p>Transcription: {{ transcription }}</p>
      <p>Translation: {{ translation }}</p> -->
    </div>
    <div v-if="error">
      <p class="error">Error: {{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  props: ['language'],
  data() {
    return {
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      transcription: '',
      translation: '',
      toast : useToast(),
    };
  },
  methods: {

    toggleRecording  ()  {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    async startRecording() {
      this.isRecording = true;
      this.error = null;
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm;codecs=opus' });
      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };
      this.mediaRecorder.onstop = this.handleStop;
      this.mediaRecorder.start();
    },
    async stopRecording() {
      this.isRecording = false;
      this.mediaRecorder.stop();
      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm;codecs=opus' });
        this.audioChunks = [];
        const linear16Audio = await this.convertToLinear16(audioBlob);
        const base64Audio = await this.convertToBase64(linear16Audio);

        try {
          const transcription = await this.transcribeAudio(base64Audio, audioBlob);
          const translation = await this.translateText(transcription);
          await this.speakText(translation);
          console.log('Emitting message:', { transcription, translation });
          this.$emit('send-message', { transcription, translation });
        } catch (error) {
          console.log('Error processing audio:', error);
          this.toast.error('Error processing audio:', error);
        }
      };
    },
    convertToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.readAsDataURL(blob);
      });
    },
    convertToLinear16 (blob)  {
    return new Promise((resolve, reject) => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const reader = new FileReader();
      reader.onload = () => {
        audioContext.decodeAudioData(reader.result, (buffer) => {
          const wavBuffer = this.audioBufferToWav(buffer);
          const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
          resolve(wavBlob);
        }, reject);
      };
      reader.readAsArrayBuffer(blob);
    });
  },
   audioBufferToWav  (buffer)  {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2 + 44;
    const buffer16 = new ArrayBuffer(length);
    const view = new DataView(buffer16);
    const channels = [];
    let offset = 0;
    let pos = 0;

    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // file length - 8
    setUint32(0x45564157); // "WAVE"

    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(buffer.sampleRate);
    setUint32(buffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2); // block-align
    setUint16(16); // 16-bit (LINEAR16)

    setUint32(0x61746164); // "data" - chunk
    setUint32(length - pos - 4); // chunk length

    for (let i = 0; i < buffer.numberOfChannels; i++) {
      channels.push(buffer.getChannelData(i));
    }

    while (pos < length) {
      for (let i = 0; i < numOfChan; i++) {
        const sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
        view.setInt16(pos, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true); // PCM
        pos += 2;
      }
      offset++; // next source sample
    }

    return buffer16;

    function setUint16(data) {
      view.setUint16(pos, data, true);
      pos += 2;
    }

    function setUint32(data) {
      view.setUint32(pos, data, true);
      pos += 4;
    }
  },
    async transcribeAudio(base64Audio, audioBlob) {
      const apiKey = 'AIzaSyAM6aY_lKcQFuL9LuQ4oMv_m7KRPyqLG4w'; 
      const languageCode = this.language === 'en' ? 'en-US' : 'es-ES';
      const audioChannelCount = await this.getAudioChannelCount(audioBlob);

      try {
        const response = await axios.post(
          `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`,
          {
            config: {
              encoding: 'LINEAR16',
              sampleRateHertz: 48000,
              languageCode,
              audioChannelCount: audioChannelCount, 
            },
            audio: {
              content: base64Audio,
            },
          }
        );

        console.log('Transcription response:', response);

        if (response.data.results) {
          return response.data.results
            .map((result) => result.alternatives[0].transcript)
            .join('\n');
        } else {
          this.toast.error('No transcription results')
          throw new Error('No transcription results');
        }
      } catch (error) {
        console.error('Error transcribing audio:', error.response?.data || error.message);
        this.toast.error('Error transcribing audio:', error.response?.data || error.message);
        throw new Error('Failed to transcribe audio');
      }
    },
    async getAudioChannelCount(blob) {
      return new Promise((resolve) => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const reader = new FileReader();
        reader.onload = () => {
          audioContext.decodeAudioData(reader.result, (buffer) => {
            resolve(buffer.numberOfChannels);
          });
        };
        reader.readAsArrayBuffer(blob);
      });
    },
    async translateText(text) {
      const apiKey = 'AIzaSyAM6aY_lKcQFuL9LuQ4oMv_m7KRPyqLG4w'; 
      const targetLanguage = this.language === 'en' ? 'es' : 'en';
      try {
        const response = await axios.post(
          `https://translation.googleapis.com/language/translate/v2`,
          null,
          {
            params: {
              q: text,
              target: targetLanguage,
              key: apiKey,
            },
          }
        );

        console.log('Translation response:', response);

        if (response.data.data && response.data.data.translations) {
          return response.data.data.translations[0].translatedText;
        } else {
          this.toast.error('No translation results')
          throw new Error('No translation results');
        }
      } catch (error) {
        this.toast.error('Error translating text:', error.response?.data || error.message);
        console.error('Error translating text:', error.response?.data || error.message);
        throw new Error('Failed to translate text');
      }
    },
    async speakText(text) {
      const apiKey = 'AIzaSyAM6aY_lKcQFuL9LuQ4oMv_m7KRPyqLG4w'; 
      const languageCode = this.language === 'en' ? 'es-US' : 'en-US';
      const voiceName = this.language === 'en' ? 'es-US-Neural2-A' : 'en-US-Neural2-E';

      try {
        const response = await axios.post(
          `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
          {
            input: { text },
            voice: {
              languageCode,
              name: voiceName,
            },
            audioConfig: {
              audioEncoding: 'MP3',
              effectsProfileId: ['handset-class-device'],
              pitch: 0,
              speakingRate: 1,
            },
          }
        );

        console.log('Text-to-Speech response:', response);

        const audioContent = response.data.audioContent;
        const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
        audio.play();
      } catch (error) {
        this.toast.error('Error synthesizing speech:', error.response?.data || error.message);
        console.error('Error synthesizing speech:', error.response?.data || error.message);
        throw new Error('Failed to synthesize speech');
      }
    },
  },
};
</script>

<style scoped>
.audio-recorder {
  margin: 20px 0;
}
button {
  padding: 10px;
  font-size: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button i {
  color: blue;
}


.error {
  color: red;
}
</style>
