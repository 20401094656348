<template>
    <div class="d-flex flex-column flex-shrink-0 vh-100" :class="{'sidebar-collapsed': isCollapsed}">
    <div class="d-flex justify-content-around nav-header">
        <img v-if="!isCollapsed" class="logo" src="./../assets/logo_sofia.png" alt="">
        <div class="p-3 text-white toggle-btn" @click="toggleSidebar">
            <i class="fa-solid fa-bars"></i>
        </div>
    </div>
    <div class="nav-upper flex-grow-1 overflow-auto">
        <EncountersList />
    </div>
    <div class="nav-lower p-3">
        <small class="m-4">version 0.0.1</small>
        <ul class="nav flex-column">
        <li class="nav-item" v-for="item in lowerNavItems" :key="item.name">
            <router-link class="nav-link text-dark" :to="item.path">
            <i :class="item.icon"></i>
            <span v-if="!isCollapsed">{{ item.name }}</span>
            </router-link>
        </li>
        <li class="nav-link text-dark" @click="logout">
                <i class="fa-solid fa-arrow-right-from-bracket"></i>
                <span> Logout</span>
            </li>
        </ul>
    </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import EncountersList from "@/pages/encountersList.vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter();
const store = useStore();

const logout = async () => {
    try {
        await store.dispatch('auth/logout');
        router.push('/login'); 
    } catch (error) {
        console.error('Logout failed:', error);

    }
    };

    const isCollapsed = ref(false);
    const toggleSidebar = () => {
        isCollapsed.value = !isCollapsed.value;
    };

    const lowerNavItems = [
        { name: 'Help', path: '/help', icon: 'fa-regular fa-circle-question' },
        { name: 'Contact', path: '/contact', icon: 'fa-solid fa-mobile-screen-button' },
    ];
</script>

<style scoped>
li{
    cursor:pointer;
}
.sidebar-collapsed {
    width: 80px;
}
.logo{
    width: 150px;
    height: 60px;
}

.nav-header{
    background-color: #1f86ae;
}
.toggle-btn {
    text-align: center;
    cursor: pointer;
}

.nav-link i {
    margin-right: 10px;
}

.sidebar-collapsed .nav-link span {
    display: none;
}

@media (max-width: 768px) {
    .sidebar-collapsed {
        width: 100%;
    }

    .nav-lower {
        flex-direction: row;
    }

    .nav-lower .nav-link {
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    }
}
</style>
