<template>
  <div id="app">
    <main>
      <div class="row m-0">
        <div v-if="isAuthenticated" class="col-12 col-md-3 p-0">
        <SideNav />
      </div>
        <router-view class="col-12 col-md-9"/>
      </div>
    </main>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import SideNav from '../src/layouts/sideNav.vue'

const store = useStore();
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);


</script>

<style>
/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9 !important;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

</style>
