<template>
  <div class="d-flex flex-column justify-content-center align-items-center ">
    <h2 class="title">Welcome to sofia Ai</h2>
    <p>Your favourite medical assistant</p>
    <div class="input-group">
      <label for="patientName" class="input-label">Patient Name</label>
      <input v-model="patientName" id="patientName" type="text" placeholder="Enter patient name" class="input-field" />
    </div>
    <div class="route-options d-flex">
      <label class="route-label mx-4">
        <input type="radio" v-model="selectedRoute" value="record" class="route-radio" />
        Companion
      </label>
      <label class="route-label mx-4">
        <input type="radio" v-model="selectedRoute" value="translate" class="route-radio" />
        Translate
      </label>
    </div>
    <div class="modal-actions">
      <button @click="handleSubmit" class="submit-btn">Start</button>
    </div>
    
  </div>
</template>

<script setup>
import { ref, nextTick  } from 'vue';
import axios from 'axios';
import { useWebSocket } from '../utils/useWebSocket';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

const store = useStore();
const patientName = ref('');
const selectedRoute = ref('');
const token = localStorage.getItem('token');
const webSocket = ref(null);
const router = useRouter();
const toast = useToast();

const handleSubmit = async () => {
  const accessToken = await store.dispatch('auth/callServiceWithUpdatedAccessToken');
  if (!accessToken) {
    console.error('No access token found, please log in again.');
    toast.error('No access token found, please log in again.');
    return;
  }

  if (!accessToken) {
    console.erroe('No access token found');
    toast.error('No access token found');

    return;
  }
  const topicId = await getNewTopicId(); 
  if (topicId) {
    const dialogId ="2";
    const patientNameV = patientName.value
    const wsUrl  = `${process.env.VUE_APP_SOFA_WS}chat/${topicId}/${token}/${dialogId}/${patientNameV}`;
if (selectedRoute.value === 'record') {
    await router.push({ name: 'record', query: { topicId, patientNameV } });
  } else if (selectedRoute.value === 'translate') {
    await router.push({ name: 'translate', query: { topicId, patientNameV } });

  } else {
    console.warn('No matching route');
    toast.warn('No matching route');
    return;
  }

  await nextTick();
    
    const handleMessage = (data) => {
      console.log('Message received from WebSocket:', data);
    };
    webSocket.value = useWebSocket(wsUrl, handleMessage);
  }

};

const getNewTopicId = async () => {
  const accessToken = localStorage.getItem('token');
  try {
    const response = await axios.get(`${process.env.VUE_APP_SOFA_API}last_chat`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'x-access-tokens': accessToken,
      },
    });

    return response.data.responseData.id; 
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await callServiceWithUpdatedAccessToken();
    } else {
      console.error("Error in getNewTopicId:", e);
      toast.error("Error in getNewTopicId:", e);
      throw e;
    }
  }
};

const callServiceWithUpdatedAccessToken = async () => {
  const updatedAccessToken = 'newAccessToken';
  localStorage.setItem('token', updatedAccessToken);
  return await getNewTopicId();
};
</script>

<style scoped>

.title {
  font-weight: 600;
  text-align: center;
  color: #1f86ae ;
}

.input-group {
  margin-bottom: 15px;
  width: 40%;
}

.input-label {
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
  color: #555;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.route-options {
  margin-bottom: 20px;
}

.route-label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  padding-left: 15px;
}

.route-radio {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 5px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.submit-btn {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 10rem;
  background-color: #4caf50;
  color: white;
  margin-right: 10px;
}

.submit-btn:hover {
  background-color: #218838;
}
</style>
