<template>
  <div class="register container mt-5">
    <div class="row justify-content-center">
      <div >
        <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid mb-4">
        <form @submit.prevent="handleRegister">
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" id="email" v-model="email" class="form-control" required />
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label">Phone Number</label>
            <input type="tel" id="phone" v-model="phone" class="form-control" required />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input type="password" id="password" v-model="password" class="form-control" required />
          </div>
          <div class="mb-3">
            <label for="clinicCode" class="form-label">Clinic Code</label>
            <input type="text" id="clinicCode" v-model="clinicCode" class="form-control" required />
          </div>
          <div class="mb-3">
            <label for="specialty" class="form-label">Select your specialty</label>
            <select v-model="selectedSpecialty" id="specialty" class="form-select" required>
              <option value="" disabled>Select a specialty</option>
              <option v-for="specialty in specialties" :key="specialty.id" :value="specialty.id">
                {{ specialty.name }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <a href="https://ensofia.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
              By registering, you agree to our terms and conditions (Here)
            </a>
          </div>
          <button type="submit" class="btn btn-primary w-100" :disabled="!isFormValid || loading">Register</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const email = ref('');
const password = ref('');
const phone = ref('');
const clinicCode = ref('');
const selectedSpecialty = ref('');
const specialties = ref([]);
const loading = ref(false);
const toast = useToast();
const router = useRouter();

const fetchSpecialties = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_SOFA_API}getSpecialtyList`);
    specialties.value = response.data;
  } catch (error) {
      toast.error('Error fetching specialties:', error);
      console.error('Error fetching specialties:', error);
  }
};

onMounted(() => {
  fetchSpecialties();
});

const isFormValid = computed(() => {
  return email.value && password.value && phone.value && clinicCode.value && selectedSpecialty.value;
});

const handleRegister = async () => {
  loading.value = true;
  try {
    const response = await axios.post(`${process.env.VUE_APP_SOFA_API}register`, {
      email: email.value,
      password: password.value,
      phone: phone.value,
      clinic_code: clinicCode.value,
      specialty_id: selectedSpecialty.value
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    console.log(response.data);
    router.push('/otp');
  } catch (error) {
    const statusCode = error.response?.status;
    if (statusCode === 409) {
      error.value("The account already exists");
      toast.error("The account already exists");
    } else if (statusCode === 201) {
      console.log("User created successfully");
      toast.success("The account already exists");
    } else {
      error.value(`Server error ${statusCode}`);
      toast.error(`Server error ${statusCode}`);
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.register {
  max-width: 400px;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}
</style>
