<template>
    <div class="chat-container1">
      <div class="d-flex justify-content-around nav-header fixed-top">
        <div class="p-3 text-white">
          <h5>Patient name: <span>{{ patientName }}</span></h5>
        </div>
      </div>
      <div v-if="!isConnected" class="text-center mt-5 pt-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Connecting...</span>
        </div>
      </div>  
      <div v-else class="d-flex chat-container">
    <div class="chat-view doctor-view">
      <div class="d-flex justify-content-around align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <h2>Doctor</h2>
          <div class="badge rounded-pill bg-info ms-3">{{ doctorLanguage }}</div>
        </div>
        <audio-recorder :language="doctorLanguage" @send-message="handleSendMessage('Doctor', $event)" />
      </div>
      <chat-display :messages="englishMessages" />
      <div class="d-flex my-3">
        <button  class="control-button" @click="extract" :disabled="isSending ">Send to Sofia</button>
        <button @click="exportConversation">Export Conversation</button>
      </div>
    </div>

    <div class="language-switch">
      <button class="switch-button" @click="switchLanguages"><i class="fa-solid fa-repeat"></i></button>
    </div>

    <div class="chat-view patient-view">
      <div class="d-flex justify-content-around align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <h2>Patient</h2>
          <div class="badge rounded-pill bg-info ms-3">{{ patientLanguage }}</div>
        </div>
        <audio-recorder :language="patientLanguage" @send-message="handleSendMessage('Patient', $event)" />
      </div>
      <chat-display :messages="spanishMessages" />
    </div>
  </div>

    <!-- Modal -->
    <div v-if="showConfirm" class="modal-overlay">
      <div class="modal-content">
        <h3>Are you sure you want to leave?</h3>
        <p>You have unsent transcription or draft. If you leave, your progress will be lost.</p>
        <div class="modal-buttons">
          <button @click="confirmLeave" class="btn btn-danger">Leave</button>
          <button @click="cancelLeave" class="btn btn-secondary">Cancel</button>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onBeforeUnmount } from 'vue';
  import ChatDisplay from './../components/ChatDisplay.vue';
  import AudioRecorder from '../components/AudioRecorder.vue';
  import { useWebSocket } from '../utils/useWebSocket';
  import { addToMessagesSofiaPlus } from '../utils/chatMessages';
  import { useRoute, useRouter } from 'vue-router'; 
  import { useToast } from 'vue-toastification';

  const router = useRouter(); 
  const route = useRoute()
  const toast = useToast();
  
  const englishMessages = ref([]);
  const spanishMessages = ref([]);
  const messageId = ref(0);
  const doctorLanguage = ref('en');
  const patientLanguage = ref('es');
  const topicId = route.query.topicId;
  const patientName = route.query.patientNameV;
  const dialogId ="2";
  const token = localStorage.getItem('token')
  const wsUrl  = `${process.env.VUE_APP_SOFA_WS}chat/${topicId}/${token}/${dialogId}/${patientName}`;
  const receivedResponse = ref('');
  const showConfirm = ref(false); // Modal control
  let resolveLeave = null; // Placeholder for confirming route leave
  const isSending = ref(false);
  
  
  const handleSendMessage = (userType, payload) => {
    console.log('Payload received in App.vue:', payload);
    if (!payload || !payload.transcription || !payload.translation) {
      console.error('Invalid payload:', payload);
      return;
    }
  
    const { transcription, translation } = payload;
    console.log('Message received in App.vue:', { transcription, translation });
  
    const newMessage = {
      id: messageId.value++,
      text: transcription,
      userType,
    };
  
    const translatedMessage = {
      id: newMessage.id,
      text: translation,
      userType,
    };
  
    if (userType === 'Doctor') {
      englishMessages.value.push(newMessage);
      spanishMessages.value.push(translatedMessage);
    } else {
      spanishMessages.value.push(newMessage);
      englishMessages.value.push(translatedMessage);
    }
  
    // console.log('Updated English Messages:', englishMessages.value);
    // console.log('Updated Spanish Messages:', spanishMessages.value);
  };
  
  const switchLanguages = () => {
    [doctorLanguage.value, patientLanguage.value] = [patientLanguage.value, doctorLanguage.value];
  };
  
  const exportConversation = () => {
    const englishDialogue = englishMessages.value.map(msg => `${msg.userType}: ${msg.text}`).join('\n');
    const spanishDialogue = spanishMessages.value.map(msg => `${msg.userType}: ${msg.text}`).join('\n');
  
    const englishBlob = new Blob([englishDialogue], { type: 'text/plain' });
    const spanishBlob = new Blob([spanishDialogue], { type: 'text/plain' });
  
    const englishUrl = URL.createObjectURL(englishBlob);
    const spanishUrl = URL.createObjectURL(spanishBlob);
  
    const englishLink = document.createElement('a');
    const spanishLink = document.createElement('a');
  
    englishLink.href = englishUrl;
    englishLink.download = 'english-conversation.txt';
    spanishLink.href = spanishUrl;
    spanishLink.download = 'spanish-conversation.txt';
  
    englishLink.click();
    spanishLink.click();
  
    URL.revokeObjectURL(englishUrl);
    URL.revokeObjectURL(spanishUrl);
    toast.success("Dialoge exported successfully");
  };

  const extract = () => {
    const englishDialogue = englishMessages.value.map(msg => `${msg.text}`).join(' ');
    const spanishDialogue = spanishMessages.value.map(msg => ` ${msg.text}`).join(' ');
  
    console.log(englishDialogue)
    console.log(spanishDialogue)
    sendToSofia(englishDialogue ,spanishDialogue)

  
  };

  const handleMessage = (receivedData) => {
  if (receivedData.source === 'sofia') {
    receivedResponse.value = receivedData.response;
    console.log('Received Sofia response:', receivedData.response);
  }
};


const { sendMessage,  isConnected } = useWebSocket(wsUrl, handleMessage);
const sendToSofia = async (englishDialogue ,spanishDialogue) => {
  if (englishDialogue.length > 0 && spanishDialogue.length > 0) {
    const messagePayload = {
      message: englishDialogue,
      orgianl_message:spanishDialogue,
      is_draft: 0,
      category_id: 'conversation between patient and doctor',
      chat_id: 0, 
      message_id: '12',
    };

    sendMessage(messagePayload); 

    addToMessagesSofiaPlus({
      id: 0,
      topicId: 0,
      body: englishMessages,
      isMe: true,
      time: new Date(),
      topicName: 'conversation between patient and doctor',
    }, true, true);

    // After sending the transcription, route to the encounter page
    router.push({ name: 'enconter', params: { chatId: topicId } , query:{patientName}});  
    console.log('Navigating to encounter page');
  }
};
// Route leave confirmation modal
router.beforeEach((to, from, next) => {
  if (!extract || isSending.value) {
    next();
  } else {
    showConfirm.value = true;
    resolveLeave = next;
  }
});

const confirmLeave = () => {
  showConfirm.value = false;
  resolveLeave();
};

const cancelLeave = () => {
  showConfirm.value = false;
};

// // Add a listener to handle browser back button or window close event
onBeforeUnmount(() => {
  if (!extract|| isSending.value) return;

  const answer = "You have unsent transcription or draft. Are you sure you want to leave?";
  if (!answer) {
    event.preventDefault();
  }
});
  </script>
  
  <style scoped>
  .chat-container1 {
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  }
  .nav-header {
  background-color: #1f86ae;
  width: 75.1%;
  left: unset;
}
  
  button {
    flex: 1;
  padding: 10px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 10rem;
  background-color: #1f86ae;
  color: white;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  .chat-container {
  display: flex;
  height: 100vh;
  position: relative;
  width: 100%;
  margin-top: 2rem;
}

.chat-view {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;

}

/* Doctor's Chat View Styling */
.doctor-view {
  background-color: #f0f8ff; /* Light blue */
  /* border-right: 2px solid #ccc; Separator between views */
}

/* Patient's Chat View Styling */
.patient-view {
  background-color: #fff3e0; /* Light orange */
}

/* Styling for the switch button */
.language-switch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.switch-button {
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 50%;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  width: 3rem;
}

.switch-button i {
  font-size: 18px;
}

.switch-button:hover {
  background-color: #0056b3;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width:50%;
  z-index: 11;
}

.modal-buttons {
  margin-top: 20px;
}
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .chat-view {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .chat-container {
      flex-direction: column;
      align-items: stretch;
    }
  
    .language-switch {
      order: -1;
      margin-bottom: 20px;
    }
  }
  </style>
  